<template>
  <b-card title="Buku">
      <b-button size="md" variant="primary" @click="add()"  v-if="$can('add', 'books')">
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        /><span>Tambah</span>
      </b-button>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Cari</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'price_formatted'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ formatPrice(props.row.price) }}</span>
        </span>

        <span
          v-if="props.column.field === 'description_formatted'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.description.length > 25) ? props.row.description.substring(0, 25) + "..." :  props.row.description }}</span>
        </span>
        
        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'updated_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap" v-if="props.row.updated_by !== null">{{ props.row.updated_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by !== null && props.row.updated_by === null">{{ props.row.created_by.name }}</span>
          <span class="text-nowrap" v-if="props.row.created_by === null && props.row.updated_by === null">-</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editModal(props.row.id)"  v-if="$can('edit', 'books')">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Ubah</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id)"  v-if="$can('delete', 'books')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Hapus</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>{{ (isEdit) ? "Ubah" : "Tambah" }} {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Tunggu..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="name">Nama:</label>
          <b-form-input
            id="name"
            name="text"
            v-model="currentObject.name"
            placeholder="Nama Buku"
          />
        </b-form-group>

        <b-form-group>
          <label for="author">Penulis:</label>
          <b-form-input
            id="author"
            name="text"
            v-model="currentObject.author"
            placeholder="Penulis Buku"
          />
        </b-form-group>

        <b-form-group>
          <label for="description">Deskripsi:</label>
          <b-form-input
            id="description"
            name="text"
            v-model="currentObject.description"
            placeholder="Deskripsi Buku"
          />
        </b-form-group>

        <b-form-group>
          <label for="isbn">ISBN:</label>
          <b-form-input
            id="isbn"
            name="number"
            v-model="currentObject.isbn"
            placeholder="Nomor ISBN"
          />
        </b-form-group>

        <b-form-group>
          <label for="price">Harga Buku:</label>
          <b-form-input
            id="price"
            name="number"
            v-model="currentObject.price"
            placeholder="Harga Buku"
          />
        </b-form-group>


        <b-form-group>
          <label for="image">Cover Buku:</label>
          <b-form-file
              id="cover_image"
              v-model="currentObject.cover_image_blob"
              accept="image/*"
              placeholder="Pilih gambar cover"
            />

            <b-img v-if="currentObject.cover_image" width="320" :src="currentObject.cover_image" fluid thumbnail class="mt-1"/>
        </b-form-group>

        <b-form-group>
          <label for="image">File Buku:</label>
          <b-form-file
              id="file_location"
              v-model="currentObject.file_location_blob"
              accept="application/pdf"
              placeholder="Pilih file buku"
            />
            <p v-if="currentObject.file_location" class="mt-1">
              <a :href="currentObject.file_location" target="_blank">Lihat file</a>
            </p>
        </b-form-group>

        <b-form-group>
          <label for="category_ids">Kategori:</label>
          <div v-if="categories.length == 0">
            <small class="ml-1">Belum ada kategori</small>
          </div>
          <b-form-checkbox-group
            id="category_ids"
            v-model="currentObject.category_ids"
            :options="categories"
            name="category_ids"
            v-if="categories.length > 0"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>

        <b-form-group>
          <label for="category_ids">Tags:</label>
          <div v-if="tags.length == 0">
            <small class="ml-1">Belum ada tag</small>
          </div>
          <b-form-checkbox-group
            id="tag_ids"
            v-model="currentObject.tag_ids"
            :options="tags"
            name="tag_ids"
            v-if="tags.length > 0"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>

      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Simpan" : "Tunggu..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Hapus {{ pageName }}</h5>
      </template>

      <p>
        Apakah anda yakin untuk menghapus data ini?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Batal
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Tunggu..." : "Hapus" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BBadge, BImg, BFormDatepicker, BFormFile, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BBadge,
    BImg,
    BFormDatepicker,
    BFormFile,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number',
        },
        {
          label: 'Nama',
          field: 'name',
        },
        {
          label: 'Deskripsi',
          field: 'description_formatted',
        },
        {
          label: 'Penulis',
          field: 'author',
        },
        {
          label: 'Harga',
          field: 'price_formatted',
        },
        {
          label: 'Tgl Perbaharuan',
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Diperbaharui Oleh',
          field: 'updated_by_user',
        },
        {
          label: 'Aksi',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "Edit",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      pageName: "Buku",

      currentObject: {
        id: -1,
        name: '',
        description: '',
        price: 0,
        author: '',
        cover_image: null,
        cover_image_blob: null,
        file_location: null,
        file_location_blob: null,
        category_ids: [],
        tag_ids: [],
      },

      categories: [],
      tags: [],
    }
  },
  computed: {
  },
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    statusVariant(status) {
      return status ? 'light-success' : 'light-danger'
    },
    add() {
        this.reset()

        this.fetchCategories()
        this.fetchTags()

        this.isEdit = false
        this.showFormModal = true

    },
    editModal(id) {
        this.reset()

        this.fetchCategories()
        this.fetchTags()

        this.isLoadingForm = true

        this.isEdit = true
        this.showFormModal = true

        this.$http.get('/admin/v1/books/' + id)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              const currentData = res.data.data
            
              this.currentObject.id = id
              this.currentObject.name = currentData.name
              this.currentObject.author = currentData.author
              this.currentObject.description = currentData.description
              this.currentObject.price = currentData.price
              this.currentObject.isbn = currentData.isbn
              this.currentObject.cover_image = currentData.cover_image
              this.currentObject.file_location = currentData.file_location

              currentData.categories.forEach((item) => {
                if(this.currentObject.category_ids.indexOf(item.id) == -1) {
                  this.currentObject.category_ids.push(item.id)
                }
              })

              currentData.tags.forEach((item) => {
                if(this.currentObject.tag_ids.indexOf(item.id) == -1) {
                  this.currentObject.tag_ids.push(item.id)
                }
              })
            }

            this.isLoadingForm = false
      })

    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
    },
    reset() {
      this.currentObject.name = ''
      this.currentObject.price = ''
      this.currentObject.author = ''
      this.currentObject.description = ''
      this.currentObject.isbn = ''
      this.currentObject.cover_image_blob = null
      this.currentObject.cover_image = null
      this.currentObject.file_location = null
      this.currentObject.file_location_blob = null
      this.currentObject.category_ids = []
      this.currentObject.tag_ids = []
      this.currentObject.id = -1
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/books/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Sukses`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchCategories() {
      this.$http.get('/admin/v1/categories')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.categories = []

            currentData.forEach((item) => {
              this.categories.push({
                value: item.id,
                text: item.name,
              })
            })
          }

          this.isLoadingForm = false
      })
    },
    fetchTags() {
      this.$http.get('/admin/v1/tags')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.tags = []

            currentData.forEach((item) => {
              this.tags.push({
                value: item.id,
                text: item.name,
              })
            })
          }

          this.isLoadingForm = false
      })
    },
    save() {
      const requestData = new FormData()

      requestData.append('name', this.currentObject.name)
      requestData.append('description', this.currentObject.description)
      requestData.append('price', this.currentObject.price)
      requestData.append('author', this.currentObject.author)

      if(this.currentObject.isbn) {
        requestData.append('isbn', this.currentObject.isbn)
      }

      if(this.currentObject.file_location_blob) {
        requestData.append('file', this.currentObject.file_location_blob)
      }

      if(this.currentObject.cover_image_blob) {
        requestData.append('cover_image', this.currentObject.cover_image_blob)
      }

      if(this.currentObject.category_ids.length == 0) {
        requestData.append('category_ids', null)
      }
      else {
        this.currentObject.category_ids.forEach((item, index) => {
          requestData.append('category_ids[' + index + ']', item)
        })
      }

      if(this.currentObject.tag_ids.length == 0) {
        requestData.append('tag_ids', null)
      }
      else {
        this.currentObject.tag_ids.forEach((item, index) => {
          requestData.append('tag_ids[' + index + ']', item)
        })
      }

      const requestHeader = { headers: { 'Content-Type': 'multipart/form-data' }}
      
      if(this.isEdit) {
        const id = this.currentObject.id

        this.$http.post('/admin/v1/books/' + id, requestData, requestHeader)
          .then(res => {         
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.fetchData()
            }

            this.isLoadingForm = false
        })

      }
      else {
        const requestData = new FormData()

        requestData.append('name', this.currentObject.name)
        requestData.append('description', this.currentObject.description)
        requestData.append('price', this.currentObject.price)
        requestData.append('author', this.currentObject.author)

        if(this.currentObject.isbn) {
          requestData.append('isbn', this.currentObject.isbn)
        }

        if(this.currentObject.file_location_blob) {
          requestData.append('file', this.currentObject.file_location_blob)
        }

        if(this.currentObject.cover_image_blob) {
          requestData.append('cover_image', this.currentObject.cover_image_blob)
        }

        this.currentObject.category_ids.forEach((item, index) => {
          requestData.append('category_ids[' + index + ']', item)
        })

        this.currentObject.tag_ids.forEach((item, index) => {
          requestData.append('tag_ids[' + index + ']', item)
        })


        const requestHeader = { headers: { 'Content-Type': 'multipart/form-data' }}

        this.$http.post('/admin/v1/books', requestData, requestHeader)
          .then(res => {
            const data = res.data
            const message = data.message

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }
            else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Sukses`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: message,
                },
              })

              this.fetchData()
            }

            this.isLoadingForm = false
        })
      }

      this.showFormModal = false
    },
    fetchData() {
      this.isLoadingTable = true

      this.$http.get('/admin/v1/books')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    isValid() {
      return this.currentObject.name !== null && this.currentObject.name !== "" &&
              this.currentObject.price !== null && this.currentObject.price !== "" && this.currentObject.price > -1 &&
              this.currentObject.author !== null && this.currentObject.author !== "" &&
              this.currentObject.description !== null && this.currentObject.description !== ""
    }
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss">

  .custom-control.custom-checkbox {
    margin-bottom: 8px;
    margin-top: 4px;
  }

</style>